import { RequestService } from './RequestService';
import queryString from 'query-string';
import { MAP_CONTROLLER_URL } from './settings';

class MapService {
  findFastigheter = async (query = undefined, params = undefined) => {
    if (!query && !params) {
      return {
        isOk: false,
        data: undefined,
        errorMessage: ''
      };
    }
    const q = queryString.stringify(
      {
        query: query || undefined,
        ...params
      },
      { arrayFormat: 'bracket' }
    );

    const url = `${MAP_CONTROLLER_URL}/find?${q}`;
    return await RequestService.getRequest(url);
  };
}

const MapServiceObj = new MapService();
export { MapServiceObj as MapService };
