window.onresize = function() {
  if (window.innerWidth <= 768) {
    if (document.getElementById('heroblock-desktop') !== null) {
      document.getElementById('heroblock-desktop').style.display = 'none';
    }
    if (document.getElementById('heroblock-mobile') !== null) {
      document.getElementById('heroblock-mobile').style.display = 'block';
    }
  } else {
    if (document.getElementById('heroblock-mobile') !== null) {
      document.getElementById('heroblock-mobile').style.display = 'none';
    }
    if (document.getElementById('heroblock-desktop') !== null) {
      document.getElementById('heroblock-desktop').style.display = 'block';
    }
  }
};
