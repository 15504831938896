import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import arrow from '../../../img/icons/dropdown-icon.svg';
import arrowWhite from '../../../img/icons/dropdown-icon--white.svg';
import { useWindowWidth, isSmall } from '../../../utils';
import { TransparentButton, BaseButton } from '../../buttons';

const Container = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  @media only screen and (max-width: 900px) {
    z-index: 1000;
    top: ${({ top }) => top};
  }
`;

const ToggleButton = styled(BaseButton)`
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  perspective: 900px;
  border: none;
  border-radius: 0.25rem;
  padding: 1.21875rem 1rem;
  background-color: ${({ isActive }) => (isActive ? '#9B0600 !important' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#ffffff ' : '#e13205 !important')};
  position: relative;
  display: grid;
  grid-template-columns: 2rem auto;
  column-gap: 1rem;

  &:before {
    transition: transform 0.2s ease;
    content: '';
    // position: absolute;
    width: 1.8em;
    height: 1.8em;
    background: url(${({ isActive }) => (isActive ? arrowWhite : arrow)});
    right: 1em;
    transform: rotateX(${({ isActive }) => (isActive ? '180deg' : 0)});
  }

  ${({ isActive }) =>
    !isActive &&
    `
    &:hover,
    &:focus-visible {
      color: #e13205;
      background-color: #fcece7;
    }
  `}
`;

const headerHeight = '2rem';
const HeaderContainer = styled.div`
  margin-top: 1.8rem;
  position: relative;
  height: ${headerHeight};
  order: 3;
  @media only screen and (max-width: 900px) {
    margin-bottom: 0;
  }
  h3 {
    margin: 0;

    display: flex;
    align-items: center;
    @media only screen and (max-width: 900px) {
      display: none;
    }
  }

  display: flex;
  align-items: flex-start;
  ${TransparentButton} {
    margin: 0;
    right: 0;
    padding: 0;
    text-align: right;
    line-height: auto;
    display: flex;
    align-items: center;
  }
`;

const WrapperWidth = styled.div`
  max-width: 1180px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media only screen and (min-width: 901px) {
    & > *:nth-child(-n + 2) {
      width: 100%;
    }

    & > *:nth-child(n + 3) {
      width: calc(50% - 1rem);
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 15px;
    background: #f5f5f5;
  }
`;

// eslint-disable-next-line
export const FilterWrapper = ({ title, children, onClickReset, hasFilters }) => {
  const [isActive, setIsActive] = useState(false);
  const [isSmallWidth, setIsSmall] = useState(isSmall());
  const [buttonHeight, setButtonHeight] = useState(false);
  const button = useRef(undefined);
  const windowWidth = useWindowWidth();

  const getButtonHeight = () => {
    if (!button || !button.current) return;
    const { height } = button.current.getBoundingClientRect();
    return height;
  };

  useEffect(() => {
    setButtonHeight(getButtonHeight());
  }, []);

  useEffect(() => {
    setIsSmall(isSmall());
  }, [windowWidth]);

  useEffect(() => {
    setButtonHeight(getButtonHeight());
  }, [isSmallWidth]);

  return (
    <>
      {isSmallWidth && (
        <ToggleButton isActive={isActive} ref={button} onClick={() => setIsActive(!isActive)}>
          Filter
        </ToggleButton>
      )}

      <Container isHidden={!isActive && isSmallWidth} top={`${buttonHeight}px`}>
        <WrapperWidth>
          <HeaderContainer isActive={isActive}>
            <TransparentButton
              isDisabled={!hasFilters}
              onClick={e => {
                onClickReset(e);
                setIsActive(false);
              }}>
              Nollställ filter
            </TransparentButton>
          </HeaderContainer>
          {children}
        </WrapperWidth>
      </Container>
    </>
  );
};

FilterWrapper.defaultProps = {
  children: undefined,
  hasFilters: false
};

FilterWrapper.propTypes = {
  hasFilters: PropTypes.bool,
  children: PropTypes.any,
  onClickReset: PropTypes.func.isRequired
};
