$(document).ready(function() {
  let buttons = document.querySelectorAll('.column-wrapper__content__exp-btn');

  buttons.forEach((button, idx) => {
    button.addEventListener('click', () => {
      let contents = document.querySelectorAll('.column-wrapper__content__text');
      let expanded = button.getAttribute('aria-expanded');

      if (expanded === 'true') {
        button.setAttribute('aria-expanded', 'false');
        button.innerHTML = 'Läs mer';
      } else {
        button.setAttribute('aria-expanded', 'true');
        button.innerHTML = 'Läs mindre';
      }
      contents.forEach((content, contentIdx) => {
        if (contentIdx === idx) {
          let hidden = content.getAttribute('aria-hidden');

          if (hidden === 'true') {
            content.setAttribute('aria-hidden', 'false');
          } else {
            content.setAttribute('aria-hidden', 'true');

            let columnHeading = document.querySelectorAll('.column-wrapper__content');
            columnHeading.forEach((heading, headingIdx) => {
              if (headingIdx === idx) {
                heading.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                //heading.parentNode.scrollTop = heading.offsetTop;
              }
            });
          }
        }
      });
    });
  });
});
