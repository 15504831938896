class Logger {
  Log = (...args) => {
    if (process.env.NODE_ENV !== 'development') return;
    console.log(...args);
  };
  Warn = (...args) => {
    if (process.env.NODE_ENV !== 'development') return;
    console.warn(...args);
  };
  Error = (...args) => {
    if (process.env.NODE_ENV !== 'development') return;
    console.error(...args);
  };
}

const Instance = new Logger();

export { Instance as Logger };
