export const rem2px = (rem = 0, includePx = true) => {
  if (!(typeof rem === 'number') || !isFinite(rem)) {
    console.error("Argument 'rem' has to be a number");
    return -1;
  }
  try {
    const body = document.querySelector('body');
    const style = window.getComputedStyle(body, null).getPropertyValue('font-size');
    const fontSize = parseFloat(style);

    const result = includePx ? `${rem * fontSize}px}` : rem * fontSize;
    return result;
  } catch (exception) {
    return -1;
  }
};
