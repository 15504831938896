(function() {
  let footer = jQuery('.icaf-footer');
  let toTheTop = jQuery('.icaf-footer-contact-info-nav-top');

  footer.ready(function() {
    let pos = footer.position();
    let height = jQuery(window).height();
    height = height - pos.top;
    height = height - footer.height();
    if (height > 0) {
      footer.css({
        'margin-top': height + 'px'
      });
    }
  });

  toTheTop.on('click', function(e) {
    jQuery('html, body').animate({ scrollTop: 0 }, 300);
    e.preventDefault();
  });
})();
