import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as BLOCKS from '../../constants';
import {
  FastighetsFilterBlock,
  FastighetsFilterContainerBlock,
  fastighetsFilterBlockPropTypes
} from '../fastighetsfilterblock';

class BlockArea extends PureComponent {
  getBlocks = () => {
    const { contentarea = [] } = this.props;
    if (!contentarea || !contentarea.length) return null;
    const blocks = contentarea.map((block, i) => {
      return this.getBlockAsComponent(block, `block_${i}`, i);
    });

    return blocks;
  };

  getBlockAsComponent = (props, key) => {
    let block;
    const type = props.blockType || props.__type__;
    switch (type) {
      case BLOCKS.REAL_ESTATE_FILTER_BLOCK:
        block = <FastighetsFilterBlock key={key} {...props} />;
        break;
      case BLOCKS.REAL_ESTATE_FILTER_CONTAINER_BLOCK:
        block = <FastighetsFilterContainerBlock key={key} {...props} />;
        break;
      default:
        break;
    }
    return block ? block : null;
  };

  render() {
    const blocks = this.getBlocks();
    return <React.Fragment>{blocks}</React.Fragment>;
  }
}

export { BlockArea };

BlockArea.propTypes = {
  contentarea: PropTypes.oneOfType([fastighetsFilterBlockPropTypes, PropTypes.any])
};
