import React from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { Input } from './Input';

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.search = debounce(this.search, 300);
    this.state = {
      searchValue: ''
    };
  }

  static propTypes = {
    reset: PropTypes.bool,
    placeholder: PropTypes.string,
    onClear: PropTypes.func,
    onSearchDone: PropTypes.func,
    isSearching: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    showReset: PropTypes.bool,
    onSearchStart: PropTypes.func,
    params: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      PropTypes.shape({})
    ]),
    //Must return a promise
    searchFunction: PropTypes.func.isRequired,
    autocompleteFunction: PropTypes.func,
    onSearchValueUpdate: PropTypes.func
  };

  static defaultProps = {
    isSearching: false,
    showReset: true,
    reset: false,
    onClear: undefined,
    placeholder: 'sök',
    onSearchDone: () => {},
    onSearchStart: () => {},
    onSearchValueUpdate: () => {},
    autocompleteFunction: async () => {}
  };

  componentDidUpdate = (
    { params: prevParams, reset: prevReset },
    { searchValue: prevSearchValue }
  ) => {
    const { params, reset, onSearchValueUpdate } = this.props;
    const { searchValue } = this.state;

    if (reset && !prevReset) {
      this.setState({ searchValue: '' });
    }

    if (searchValue !== prevSearchValue) {
      this.search(searchValue);
      this.autocomplete(searchValue);
      onSearchValueUpdate(searchValue);
    }

    if (isEqual(params, prevParams)) return null;
    this.search(searchValue);
  };

  componentDidMount = () => {
    window.addEventListener('click', this.handleClickOutside);
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.handleClickOutside);
  };

  handleClickOutside = () => {
    this.setState({ autocomplete: [] });
  };

  search = async value => {
    const { onSearchDone, searchFunction, onSearchStart, params } = this.props;
    onSearchStart(value);
    this.setState({ errorMessage: '' });
    const result = await searchFunction(value, params);
    this.setState({ errorMessage: result.errorMessage });
    onSearchDone(result);
  };

  autocomplete = async () => {
    const { searchValue } = this.state;
    const { autocompleteFunction } = this.props;

    const { isOk, data } = await autocompleteFunction(searchValue);
    this.setState({ autocomplete: isOk ? data : [] });
    return data;
  };

  handleSearchChange = async ({ target: { value } }) => {
    this.setState({ searchValue: value, errorMessage: '' });
  };

  render() {
    const { searchValue, errorMessage, autocomplete = [] } = this.state;
    const { placeholder, isSearching, onClear, showReset } = this.props;
    return (
      <Input
        showReset={showReset}
        errorMessage={errorMessage}
        isSearching={isSearching}
        isSearch
        autocomplete={autocomplete}
        onAutoCompleteSelect={value => this.handleSearchChange({ target: { value: value } })}
        onChange={this.handleSearchChange}
        value={searchValue}
        placeholder={placeholder}
        onClear={onClear}
      />
    );
  }
}

export { SearchInput };
