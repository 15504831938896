(function() {
  // Large and up screens do not have this Foundation topbar menu, therefor we do not continue
  if (Foundation.utils.is_large_up()) {
    return;
  }

  let $topBarContainer = jQuery('div[data-nav-parent]');
  let $nav = $topBarContainer.find('nav.top-bar');
  let $menuButton = $topBarContainer.find('li.menu-icon');
  let lastScrollTop = 0;

  jQuery(window).on(
    'scroll',
    Foundation.utils.debounce(
      function(e) {
        if ($nav.hasClass('expanded')) {
          return;
        }

        let scrollY = e.currentTarget.scrollY;

        // SET CLASS IF USER SCROLLING DOWN
        $topBarContainer.toggleClass('top-bar-sticky-up', scrollY > lastScrollTop);

        lastScrollTop = scrollY;
      },
      300,
      false
    )
  );

  $menuButton.on('click', function(e) {
    e.preventDefault();
    jQuery(this)
      .find('.ss-icon')
      .toggleClass('ss-rows ss-delete');
  });
})();
