document.addEventListener('DOMContentLoaded', function() {
  if (!document.querySelector('.header')) return;

  const state = function(initial, onStateChange) {
    let state = initial;
    return [
      () => state,
      value => {
        state = value;
        onStateChange(state);
      }
    ];
  };

  const [dropdownOpen, setDropdownOpen] = state(false, newState => {
    const dropdownButton = document.querySelector('.header__dropdown-button');
    const dropdownMenu = document.getElementById('dropdown-menu');

    dropdownButton.setAttribute('aria-expanded', newState);
    dropdownMenu.setAttribute('aria-hidden', !newState);

    dropdownMenu.classList.toggle('header__dropdown-menu--visible', newState);

    dropdownButton
      .querySelector('svg use')
      .setAttribute('xlink:href', newState ? '#xmark' : '#bars');
    dropdownButton.setAttribute('aria-label', newState ? 'Stäng meny' : 'Öppna meny');

    document.getElementById('focus-trap__start').setAttribute('tabindex', newState ? '0' : '-1');
    document.getElementById('focus-trap__end').setAttribute('tabindex', newState ? '0' : '-1');
  });

  document.querySelector('.header__dropdown-button').addEventListener('click', function() {
    setDropdownOpen(!dropdownOpen());
  });

  const [searchOpen, setSearchOpen] = state(false, newState => {
    const searchButton = document.querySelector('.header__search-button');
    const searchArea = document.getElementById('search-area');

    searchButton.setAttribute('aria-expanded', newState);
    searchArea.setAttribute('aria-hidden', !newState);

    searchArea.classList.toggle('header__search-area--visible', newState);

    searchButton
      .querySelector('svg use')
      .setAttribute('xlink:href', newState ? '#xmark' : '#magnifying-glass');
    searchButton.setAttribute('aria-label', newState ? 'Stäng sökfält' : 'Öppna sökfält');
  });

  document.querySelector('.header__search-button').addEventListener('click', function() {
    setSearchOpen(!searchOpen());
  });

  document.addEventListener('click', function(event) {
    if (!document.querySelector('.header').contains(event.target) && dropdownOpen()) {
      setDropdownOpen(false);
    }

    if (!document.querySelector('.header').contains(event.target) && searchOpen()) {
      setSearchOpen(false);
    }
  });

  document.addEventListener('keydown', function(event) {
    if (event.key === 'Escape' && dropdownOpen()) {
      setDropdownOpen(false);
    }

    if (event.key === 'Escape' && searchOpen()) {
      setSearchOpen(false);
    }
  });

  // Focus trap
  const setFocus = function(to) {
    const focusableContent = document.querySelectorAll('.header .focus-trap [tabindex="0"]');
    const firstFocusableElement = focusableContent[1];
    const lastFocusableElement = focusableContent[focusableContent.length - 2];

    if (to === 'first') {
      firstFocusableElement.focus();
    } else if (to === 'last') {
      lastFocusableElement.focus();
    }
  };

  document.getElementById('focus-trap__start').addEventListener('focus', function() {
    setFocus('last');
  });

  document.getElementById('focus-trap__end').addEventListener('focus', function() {
    setFocus('first');
  });

  // Mobile icon
  document
    .querySelector('.header__dropdown-button.menu-icon.toggle-topbar')
    .addEventListener('click', function() {
      const svgUseElement = this.querySelector('use');
      const currentHref = svgUseElement.getAttribute('xlink:href');

      if (currentHref === '#bars') {
        svgUseElement.setAttribute('xlink:href', '#xmark');
      } else {
        svgUseElement.setAttribute('xlink:href', '#bars');
      }
    });
});
