import { RequestService } from './RequestService';
import queryString from 'query-string';
import { FIND_CONTROLLER_URL } from './settings';

class FindService {
  getAutocomplete = async (q = undefined) => {
    if (!q || !q.length) {
      return {
        isOk: false,
        data: [],
        errorMessage: ''
      };
    }
    const query = queryString.stringify({
      query: q || undefined
    });

    const url = `${FIND_CONTROLLER_URL}/autocomplete?${query}`;
    return await RequestService.getRequest(url);
  };
}

const FindServiceObj = new FindService();
export { FindServiceObj as FindService };
