$(document).ready(function() {
  $('[data-icaf-load-more]').on('click', function(e) {
    e.preventDefault();

    const $that = $(this);
    const ajaxUrl = $that.attr('data-icaf-load-more-ajax-url');
    const $ajaxLoadMoreTarget = $($that.attr('data-icaf-load-more-ajax-target'));
    const $pageCountTarget = $($that.attr('data-icaf-load-more-pagecount-target'));
    const shouldScroll = $that.attr('data-icaf-load-more-should-scroll') === 'true';
    const dataType = $that.attr('data-icaf-load-more');
    let loadMoreUrl = '';
    let view = '';
    let hasMoreHits = false;

    $.get(ajaxUrl)
      .then(function(response) {
        if (dataType == 'xml') {
          let tmpResponse = response;
          response = $("<div class='xmldata'></div>");
          response.html(tmpResponse);

          loadMoreUrl = $(response)
            .find('input[type=hidden]:first-child')
            .val();
          hasMoreHits = loadMoreUrl;
          view = $(response).unwrap();
        } else {
          loadMoreUrl = response.loadMoreUrl;
          hasMoreHits = !!response.hasMoreHits;
          view = window.app.displayTemplates.searchPage(response.hits.Hits);
        }

        const $lastRow = $ajaxLoadMoreTarget.find('.row:last');

        $that.attr('data-icaf-load-more-ajax-url', loadMoreUrl);
        $ajaxLoadMoreTarget.append(view);
        $pageCountTarget.html($ajaxLoadMoreTarget.find('>.row').length);
        $that.closest('.row').toggleClass('hide', !hasMoreHits);

        $(document).foundation('equalizer', 'reflow');
        $(document).foundation('interchange', 'reflow');

        if (shouldScroll && $lastRow.length) {
          $('html, body').animate(
            {
              scrollTop: $lastRow.offset().top
            },
            600
          );
        }
      })
      .fail(function(error) {
        console.error(error);
      });
  });
});
