import styled from 'styled-components/macro';
import { ICA_RED, ICA_PINK, TEXT_REGULAR } from '../../constants';

export const PrimaryToolTip = styled.div`
  z-index: 15000;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  font-family: ${TEXT_REGULAR};
  background: ${ICA_PINK};
  height: auto;
  font-size: 0.9375rem;
  letter-spacing: 2px;
  padding: 10px;
  width: auto;
  min-width: 300px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-width: 200px;
  }

  line-height: 1.45em;
  position: ${({ absolute, fixed }) => (absolute ? 'absolute' : fixed ? 'fixed' : 'relative')};

  &:hover {
    cursor: pointer;
  }

  .tt-inner {
    width: 100%;
    img {
      height: 120px;
      margin-bottom: 5px;
      display: flex;
    }
    h3 {
      font-family: ${TEXT_REGULAR};
      color: ${ICA_RED};
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 10px 0;
      font-weight: bold;
    }

    &__text {
      background: #fff;
      padding: 10px;
      p {
        margin: 0;
        font-weight: bold;
      }
      &__pre {
        font-weight: normal !important;
        margin-top: 10px !important;
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      font-family: ${TEXT_REGULAR};
      color: ${ICA_RED};
      word-break: break-word;
      margin-top: 15px;
      letter-spacing: 0;

      transition: 0.2s ease all;

      &:hover,
      &:focus {
        color: #000;

        &:after {
          filter: grayscale(1);
        }
      }

      &:after {
        content: ' ';
        width: 15px;
        height: 15px;
        background: url(/areas/icafastigheter/app/img/icons/pil.svg);
        display: inline-block;
        background-repeat: no-repeat;
        margin-left: 8px;
        transition: 0.2s ease all;
      }
    }
  }
`;
