(function() {
  let $thumbnailGallery = jQuery('ul.galleryblock-thumbnail-list');

  let markup = '';

  jQuery('.galleryblock .galleryblock-slider li').each(function() {
    let dataInterchange = jQuery(this)
      .find('div')
      .attr('data-interchange');
    let dataUUID = jQuery(this)
      .find('div')
      .attr('data-uuid');
    let backgroundImage = jQuery(this)
      .find('div')
      .css('background-image');
    jQuery(this).attr('data-orbit-slide', 'nr-' + jQuery(this).index());

    markup +=
      '<li data-orbit-link=nr-' +
      jQuery(this).index() +
      '>' +
      '<div data-interchange=' +
      dataInterchange +
      ' style=background-image:' +
      backgroundImage +
      ' data-uuid=' +
      dataUUID +
      '>' +
      '</div></li>';
  });

  $thumbnailGallery.html(markup);
  $thumbnailGallery.find('li:first-child').addClass('active');
  $thumbnailGallery.removeClass('hide');
})();
