import styled from 'styled-components/macro';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { PrimaryToolTip } from '../../tooltip';
import markerIcon from '../../../img/icons/map-marker.svg';
import selectedMarkerIcon from '../../../img/icons/map-marker-selected.svg';
import { ICA_BLACK, ICA_RED } from '../../../constants';

const StyledMarker = styled.div`
  background: url(${({ isSelected }) => (isSelected ? selectedMarkerIcon : markerIcon)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 1.5rem;
  height: 2rem;
  left: 50%;
  z-index: ${({ zindex }) => (zindex ? zindex : 0)};
  right: 50%;
  transform: translate3d(-50%, -50%, 0);
  &:hover {
    cursor: pointer;
  }

  ${PrimaryToolTip} {
    position: absolute;
    bottom: 35px;
  }
`;

const clusterSize = {
  width: 2,
  height: 2
};
const StyledCluster = styled.div`
  z-index: 1;
  border-radius: 50%;
  width: ${`${clusterSize.width + 0.5}rem`};;
  height: ${`${clusterSize.width + 0.5}rem`};;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: transform .3s ease;
  transform: ${({ isSelected }) =>
    isSelected ? 'translate3d(-50%, -50%, 0) scale(1.4)' : 'translate3d(-50%, -50%, 0)'};

  &:hover {
    cursor: pointer;
  }
  &:before {
    transition: background .3s ease;
    background: ${({ isSelected }) => (isSelected ? ICA_BLACK : ICA_RED)};
    content: "";
    border-radius: 50%;
    width: ${`${clusterSize.width}rem`};
    height: ${`${clusterSize.height}rem`};
    
  }
  &:after{
    content: '${({ count }) => count}';
    color: white;
    font-weight: bold;
    position: absolute;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      left: 50%;
      top:50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
`;
const formatPostCodes = postCode => {
  const r = /^\d{3} \d{2}$/;
  try {
    if (r.test(postCode)) return postCode;
    return postCode.replace(/(\d{3})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
  } catch (ex) {
    return postCode;
  }
};
export const Marker = ({
  lng,
  lat,
  id,
  name,
  cluster,
  point_count,
  isSelected,
  onActiveChange,
  // eslint-disable-next-line
  data
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const node = useRef(undefined);
  const tooltip = useRef(undefined);

  const handleClick = e => {
    if (!node.current) return;
    if (!tooltip.current) return;

    if (e.target === tooltip.current) {
      return;
    }
    setTooltipVisible(false);
  };

  // eslint-disable-next-line
  const handleMarkerClick = e => {
    if (!node.current) return;
    if (!tooltip.current) return;

    onActiveChange({ isActive: !isTooltipVisible, ref: node.current, text: data.address });
    setTooltipVisible(!isTooltipVisible);
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const props = {
    lng: lng,
    lat: lat,
    id: id,
    count: point_count,
    isSelected: isSelected,
    name: name
  };
  return cluster ? (
    <StyledCluster {...props} />
  ) : (
    <StyledMarker
      zindex={isTooltipVisible ? 2 : 0}
      {...props}
      ref={node}
      onClick={handleMarkerClick}>
      <PrimaryToolTip ref={tooltip} isVisible={isTooltipVisible}>
        <div className="tt-inner">
          <h3>{name}</h3>
          {data.image && <img src={data.image} />}
          <div className="tt-inner__text">
            <p>{data.address}</p>
            <p>
              {formatPostCodes(data.postCode)}, {data.postArea}
            </p>
            {data.preamble && <p className="tt-inner__text__pre">{data.preamble}</p>}
            {data.link && <a href={data.link}>{data.linkText || `G� till fastigheten`}</a>}
          </div>
        </div>
      </PrimaryToolTip>
    </StyledMarker>
  );
};

Marker.defaultProps = {
  name: '',
  cluster: false,
  point_count: 1,
  isSelected: false,
  onActiveChange: () => {}
};

Marker.propTypes = {
  lng: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string,
  cluster: PropTypes.bool,
  point_count: PropTypes.number,
  isSelected: PropTypes.bool,

  onActiveChange: PropTypes.func
};
