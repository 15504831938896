(function() {
  jQuery('.icaf-cite').each(function(index, element) {
    let $citesCited = jQuery(element)
      .next('.icaf-cited')
      .first();

    if ($citesCited.length) {
      jQuery(element)
        .next($citesCited)
        .andSelf()
        .wrapAll('<div class="icaf-cite-container"/>');
    } else {
      jQuery(element).wrap('<div class="icaf-cite-container"/>');
    }
  });
})();
