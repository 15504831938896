import PropTypes from 'prop-types';
import { REAL_ESTATE_FILTER_BLOCK } from '../../constants';
export const fastighetsFilterBlockPropTypes = PropTypes.shape({
  filterCategory: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, value: PropTypes.number })
  ),
  header: PropTypes.string,
  image: PropTypes.string,
  onSelect: PropTypes.func,
  onDeSelect: PropTypes.func,
  __type__: REAL_ESTATE_FILTER_BLOCK
});
