/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { FilterBlockContainer, FilterItemGrid, FilterItemColumn } from './components';
import { BlockArea } from '../blockarea';

class FastighetsFilterContainerBlock extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };
  }

  componentDidUpdate = (prevProps, { selected: prevSelected }) => {
    const { selected } = this.state;
    const { onFilterClear, onFilterAdd, onFilterRemove, reset } = this.props;
    const { reset: prevReset } = prevProps;

    if (reset && !prevReset) {
      this.setState({ selected: [] });
      onFilterClear();

      return;
    }
    if (isEqual(prevSelected, selected)) return null;
    if (prevSelected.length > selected.length) onFilterRemove(selected);
    if (prevSelected.length < selected.length) onFilterAdd(selected);
  };

  handleSelect = filter => {
    const { value } = filter;
    const { onFilterRemove, onFilterAdd } = this.props;
    const { selected } = this.state;
    const isSelected = selected.filter(({ value: fValue }) => fValue === value).length;
    if (isSelected) {
      this.setState({ selected: selected.filter(({ value: fValue }) => fValue !== value) });
      onFilterRemove(filter);
      return;
    } else {
      onFilterAdd(filter);
      this.setState({ selected: [...selected, filter] });
    }
  };

  render() {
    const { selected } = this.state;
    const { header, realEstateFilters } = this.props;
    if (!realEstateFilters || !realEstateFilters.length) return null;
    const filters = (realEstateFilters || []).map(block => {
      const {
        filterCategory: [{ value }]
      } = block;

      return {
        ...block,
        isSelected: selected.filter(({ value: fValue }) => fValue === value).length ? true : false,
        onChange: this.handleSelect
      };
    });

    return (
      <FilterBlockContainer>
        <span>{header}</span>
        <FilterItemGrid>
          <BlockArea contentarea={filters} />
        </FilterItemGrid>
        <FilterItemColumn>
          <BlockArea contentarea={filters} />
        </FilterItemColumn>
      </FilterBlockContainer>
    );
  }
}

FastighetsFilterContainerBlock.defaultProps = {
  onFilterClear: () => {},
  onFilterRemove: () => {},
  onFilterAdd: () => {},
  header: '',
  filters: [],
  reset: false
};

FastighetsFilterContainerBlock.propTypes = {
  realEstateFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  onFilterClear: PropTypes.func,
  onFilterRemove: PropTypes.func,
  onFilterAdd: PropTypes.func,
  header: PropTypes.string,
  reset: PropTypes.bool
};

export { FastighetsFilterContainerBlock };
