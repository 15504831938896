import GoogleMapReact from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';
import React from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker } from './components';
import { rem2px, Logger } from '../../utils';
import SuperCluster from 'supercluster';
class GoogleMaps extends React.Component {
  constructor(props) {
    super(props);

    this.mapContainer = React.createRef();
    const { initialZoom, markers, initialCenter } = this.props;
    //maxZoom = 22
    //minzoom = 2
    const clusterRadius = rem2px(6, false);
    this.clusterIndex = new SuperCluster({ radius: clusterRadius, maxZoom: 17, minZoom: 2 });
    const points = this.convertMarkersToPoints(markers);
    this.clusterIndex.load(points);

    this.state = {
      selectedId: -1,
      leaves: [],
      zoom: initialZoom,
      center: initialCenter,
      maxZoom: 17,
      minZoom: 2,
      currentBounds: {},
      isManipulatingMap: false,
      clusters: [],
      googleAPILoaded: false
    };
  }

  componentDidMount = () => {
    const { userCoordinates } = this.props;

    this.mapContainer.current.addEventListener('mousedown', this.handleMouseDown);
    this.mapContainer.current.addEventListener('mouseup', this.handleMouseUp);
    if (userCoordinates) this.setState({ center: userCoordinates });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { currentBounds, googleAPILoaded } = this.state;
    const {
      currentBounds: prevBounds,
      googleAPILoaded: prevGoogleAPILoaded,
      reset: prevReset
    } = prevState;

    if (!googleAPILoaded) return null;

    const { userCoordinates, markers, reset, initialZoom, initialCenter } = this.props;

    if (reset && !prevReset) {
      this.setState({
        selectedId: -1,
        zoom: initialZoom,
        center: userCoordinates ? userCoordinates : initialCenter
      });
    }

    if (!userCoordinates && googleAPILoaded && !prevGoogleAPILoaded) {
      const { center, zoom, newBounds } = this.fitToMarkerBounds(markers);
      this.handleUpdateClusters(center, zoom, newBounds);
      return null;
    }

    //The users geolocation coordinates has been updated
    const { userCoordinates: prevUserCoordinates, markers: prevMarkers } = prevProps;
    if (
      (userCoordinates && userCoordinates !== prevUserCoordinates) ||
      (userCoordinates && googleAPILoaded && !prevGoogleAPILoaded)
    ) {
      this.setState({ center: userCoordinates, zoom: 15 }, () => {});
      return null;
    }

    //A search has been made
    if (markers && prevMarkers !== markers) {
      const points = this.convertMarkersToPoints(markers);
      this.clusterIndex.load(points);

      const { center, zoom, newBounds } = this.fitToMarkerBounds(markers);
      this.handleUpdateClusters(center, zoom, newBounds);

      return null;
    }

    //The bounds has changed. (user has manipulated the map)
    if (
      currentBounds !== prevBounds ||
      (currentBounds && googleAPILoaded && !prevGoogleAPILoaded)
    ) {
      this.handleUpdateClusters();
      return null;
    }
  };

  isAllMarkersTheSame = (markers = []) => {
    try {
      const allEqual = (arr, fn) => arr.every(val => fn(val, arr[0]));

      const result = allEqual(markers, ({ lng, lat }, { lng: firstLng, lat: firstLat }) => {
        const lngIsEqual = lng === firstLng;
        const latIsEqual = lat === firstLat;
        return lngIsEqual && latIsEqual;
      });

      return result;
    } catch (ex) {
      Logger.Error(`%c failed in isAllMarkersTheSame`, 'color: red');
      return false;
    }
  };

  fitToMarkerBounds = (markers = []) => {
    const { center, currentBounds, zoom } = this.state;
    const failResult = { center: center, zoom: zoom, newBounds: currentBounds };
    const { onChildSelect } = this.props;
    if (!markers || !markers.length) return failResult;

    if (markers.length === 1 || this.isAllMarkersTheSame(markers)) {
      // eslint-disable-next-line no-unused-vars
      const [first, ...rest] = markers;
      onChildSelect(markers);
      const result = { center: { lat: first.lat, lng: first.lng }, zoom: 15 };
      Logger.Log('The new center is', result);
      return result;
    }

    const bounds = new google.maps.LatLngBounds();
    markers.forEach(({ lat, lng }) => {
      bounds.extend({ lat: lat, lng: lng });
    });

    const { lat: neLat, lng: neLng } = bounds.getNorthEast().toJSON();
    const { lat: swLat, lng: swLng } = bounds.getSouthWest().toJSON();

    const newBounds = {
      ne: {
        lat: neLat,
        lng: neLng
      },
      sw: {
        lat: swLat,
        lng: swLng
      }
    };

    try {
      Logger.Log('The new bounds are', newBounds);
      const result = fitBounds(newBounds, this.getMapSize());
      return result;
    } catch (ex) {
      return failResult;
    }
  };

  getMapSize = () => {
    const { width, height } = this.mapContainer.current.getBoundingClientRect();
    return {
      width: width,
      height: height
    };
  };

  handleUpdateClusters = (newCenter = undefined, newZoom = undefined, newBounds = undefined) => {
    const { currentBounds, zoom, center } = this.state;

    let updatedZoom = newZoom || zoom;
    let updatedCenter = newCenter || center;
    const {
      sw: { lng: westLng, lat: southLat },
      ne: { lng: eastLng, lat: northLat }
    } = newBounds || currentBounds;

    const clusters = this.clusterIndex.getClusters(
      [westLng, southLat, eastLng, northLat],
      updatedZoom
    );

    this.setState({ clusters: clusters, center: updatedCenter, zoom: updatedZoom });
  };

  convertMarkersToPoints = (markers = []) => {
    const converted = markers.map(({ lat, lng, text, id, ...rest }) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        properties: {
          name: text,
          id: id,
          ...rest
        }
      };
    });
    return converted;
  };

  createMapOptions = () => {
    return {
      gestureHandling: 'greedy',
      minZoom: 2,
      maxZoom: 17,
      mapTypeControl: false,
      zoomControl: true,
      panControl: false
    };
  };

  handleMouseDown = () => {
    const { isManipulatingMap } = this.state;
    if (isManipulatingMap) return null;
    this.setIsManipulatingMap('handleMouseDown', true);
  };
  handleMouseUp = () => {
    const { isManipulatingMap } = this.state;
    if (!isManipulatingMap) return null;
    this.setIsManipulatingMap('handleMouseUp', false);
  };

  handleOnChildClick = (index, { id, cluster_id, cluster }) => {
    const { onChildSelect, markers } = this.props;
    const { zoom, maxZoom } = this.state;
    let selectedMarkers = [];
    let newZoom = zoom;
    if (cluster) {
      newZoom = this.clusterIndex.getClusterExpansionZoom(id);
      const childPoints = this.clusterIndex.getLeaves(id, Infinity);
      selectedMarkers = childPoints.map(({ properties: { id } }) => {
        return markers.find(({ id: markerId }) => id === markerId);
      });
    } else {
      newZoom = maxZoom;
      selectedMarkers = [markers.find(({ id: markerId }) => id === markerId)];
    }

    const center = {
      lat: selectedMarkers[0].lat,
      lng: selectedMarkers[0].lng
    };

    onChildSelect(selectedMarkers);
    this.setState({ selectedId: id || cluster_id, center: center, zoom: newZoom });
  };

  handleOnChange = ({ zoom, bounds }) => {
    this.setState({ currentBounds: bounds, zoom: zoom });
  };

  handleDrag = () => {
    const { isManipulatingMap } = this.state;
    if (isManipulatingMap) return null;
    this.setIsManipulatingMap('handleDrag', true);
  };

  handleZoomStart = () => {
    const { isManipulatingMap } = this.state;
    if (isManipulatingMap) return null;
    this.setIsManipulatingMap('handleZoomStart', true);
  };
  handleZoomEnd = () => {
    const { isManipulatingMap } = this.state;
    if (!isManipulatingMap) return null;
    this.setIsManipulatingMap('handleZoomEnd', false);
  };

  setIsManipulatingMap = (event, isManipulating) => {
    this.setState({ isManipulatingMap: isManipulating });
  };

  handleGoogleAPILoaded = () => {
    this.setState({ googleAPILoaded: true });
  };

  handleChildActiveChange = ({ isActive, ref, text }) => {
    const { onChildActiveChange } = this.props;
    onChildActiveChange({ isActive, ref, text });
  };

  render() {
    const { initialZoom, initialCenter, mapsKey, height, markers } = this.props;
    const { clusters = [], selectedId, zoom, center } = this.state;
    return (
      <MapContainer height={height || '50vh'} ref={this.mapContainer}>
        <GoogleMapReact
          onZoomAnimationStart={this.handleZoomStart}
          onZoomAnimationEnd={this.handleZoomEnd}
          onDrag={this.handleDrag}
          onGoogleApiLoaded={this.handleGoogleAPILoaded}
          onChange={this.handleOnChange}
          onChildClick={this.handleOnChildClick}
          options={this.createMapOptions}
          bootstrapURLKeys={{ key: mapsKey }}
          debounced={true}
          defaultCenter={initialCenter}
          yesIWantToUseGoogleMapApiInternals
          center={center}
          zoom={zoom}
          defaultZoom={initialZoom}>
          {clusters.map(({ properties, geometry: { coordinates }, id }, index) => {
            const props = {
              lng: coordinates[0],
              lat: coordinates[1],
              isSelected: selectedId === id || properties.id == selectedId || markers.length === 1,
              ...properties,
              id: properties.id || id
            };
            return <Marker onActiveChange={this.handleChildActiveChange} key={index} {...props} />;
          })}
        </GoogleMapReact>
      </MapContainer>
    );
  }
}

GoogleMaps.defaultProps = {
  initialZoom: 7,
  markers: [],
  onChildSelect: () => {},
  key: '',
  height: '50vh',
  reset: false,
  onChildActiveChange: () => {}
};

const marker = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
  text: PropTypes.string
});

GoogleMaps.propTypes = {
  onChildActiveChange: PropTypes.func,
  reset: PropTypes.bool,
  onChildSelect: PropTypes.func,
  initialCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  initialZoom: PropTypes.number,
  markers: PropTypes.arrayOf(marker),
  userCoordinates: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  mapsKey: PropTypes.string,
  height: PropTypes.string
};

export { GoogleMaps };
