/* eslint-disable */
import styled, { keyframes } from 'styled-components/macro';
import React from 'react';
import InlineSVG from 'svg-inline-react';
import PropTypes from 'prop-types';
import searchIcon from '../../img/icons/search.svg?inline';
import { BaseList, BaseListItem } from '../list';
import { ICA_RED, WHITE, LIGHT_GRAY } from '../../constants';
import { BaseButton } from '../buttons';
const StyledInput = styled.input.attrs(({ placeholder }) => ({
  type: 'text',
  placeholder: placeholder
}))`
  display: inline-flex !important;
  align-items: center !important;
  width: 100% !important;
  min-height: 56px !important;
  margin-bottom: 0 !important;
  padding: 12px 47px 12px 1rem !important;
  background-color: #ffffff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #bdbbb9 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 0 0 0 rgba(240, 136, 182, 0.2) !important;
  transition: box-shadow .2s ease-in-out,border-color .2s ease-in-out !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  color: #3a3a3a !important;
  // padding: ${({ isSearch }) => (isSearch ? '0 0 0 2.5rem !important' : 0)};

  &:focus {
    border-color: #f088b6 !important;
    box-shadow: 0 0 0 3px rgba(240, 136, 182, 0.2) !important;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  z-index: 95;
  // max-width: 460px;
  width: 100%;
`;

const zoomOut = keyframes`
  50% {
    transform: scale(.8);
  }
`;

const move = keyframes`
  0%  { transform: translate(70px, 70px); }
  20% { transform: translate(0, 0); }
  40% { transform: translate(-50px, 50px); }
  60% { transform: translate(-50px, -50px); }
  80% { transform: translate(50px, 0); }
  100% { transform: translate(0, 0); }
`;

const blink = keyframes`
  from { opacity: .25; }
  to { opacity: 1; }
`;

const mangoAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const animationSpeed = 0.8;
const Center = styled.div.attrs(({ isSearching }) => ({
  className: isSearching ? 'active' : ''
}))`
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0.7rem;
  transform: translate3d(0, -50%, 0);
  z-index: 1;

  svg {
    .ojo {
      opacity: 0;
    }

    .mango {
      opacity: 1;
    }
  }

  &.active {
    svg {
      cursor: pointer;

      .mango {
        opacity: 0;
        animation: ${mangoAnimation} ${`${animationSpeed * 0.5}s`} forwards;
        animation-delay: ${`${animationSpeed * 3}s`};
      }

      .ojo {
        animation: ${move} ${`${animationSpeed * 1.5}s`}, ${blink} ${`${animationSpeed * 0.25}s`} 9;
        transform-origin: 50% 50%;
        animation-delay: ${`${animationSpeed * 0.6}s`};
      }

      .lupa {
        animation: ${zoomOut} ${`${animationSpeed * 0.5}s`};
        transform-origin: 50%;
      }

      .mangoPunto {
        transition: transform ${`${animationSpeed * 0.1}s`};
        transition-delay: ${`${animationSpeed * 0.5}s`};
        transform: translate(-75px, -75px);
      }
    }
  }
`;

const SearchIcon = props => {
  return (
    <Center {...props}>
      <InlineSVG src={searchIcon} />
    </Center>
  );
};

export const ErrorMessage = styled.p`
  position: absolute;
  top: 100%;
  margin: 0;
  width: 100%;
  right: 0;
  background: #fff;
  text-align: center;
  padding: 0.3rem;
  transition: transform 0.24s ease;
  transform-origin: top;
  transform: rotateX(${({ hasError }) => (hasError ? '0deg' : '-90deg')});
  opacity: ${({ hasError }) => (hasError ? 1 : 0)};
  box-shadow: 1px 2px 4px #00000021;
  color: #333333;
  z-index: -1;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
`;

const Autocomplete = styled(BaseList)`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 100;
  background: ${WHITE};
  box-shadow: 3px 3px 5px #ccc;
  color: #333333;
`;

const AutocompleteItem = styled(BaseListItem)`
  padding: 0.3rem;
  cursor: pointer;
  &:hover {
    background: ${LIGHT_GRAY};
  }
`;

const ResetButton = styled(BaseButton)`
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.24s ease;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  overflow: hidden;
  top: 50%;
  transform: translateY(-50%);

  &:before {
    content: '\\d7';
    font-size: 2.2rem;
    color: #000;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    transition: transform 0.2s ease;
    transform: translateX(${({ hidden }) => (hidden ? '1.5rem' : 0)});

    display: flex;
    align-items: center;
  }
`;

export const Input = ({
  isSearch,
  isSearching,
  errorMessage,
  autocomplete = [],
  onBlur = () => {},
  onClear = () => {},
  onAutoCompleteSelect,
  showReset,
  ...rest
}) => {
  return (
    <InputWrapper isSearch={isSearch}>
      {
        //isSearch && <SearchIcon isSearching={isSearching} />
      }
      <StyledInput isSearch={isSearch} {...rest} onBlur={onBlur} />
      <ResetButton hidden={!showReset} onClick={onClear} />
      {isSearch && <ErrorMessage hasError={errorMessage ? 1 : 0}>{errorMessage} </ErrorMessage>}
      {isSearch && (
        <Autocomplete hide={errorMessage || autocomplete.length < 2}>
          {autocomplete.map(text => (
            <AutocompleteItem onClick={() => onAutoCompleteSelect(text)} key={text}>
              {text}
            </AutocompleteItem>
          ))}
        </Autocomplete>
      )}
    </InputWrapper>
  );
};

Input.propTypes = {
  showReset: PropTypes.bool,
  isSearch: PropTypes.bool,
  errorMessage: PropTypes.string,
  isSearching: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  autocomplete: PropTypes.arrayOf(PropTypes.string),
  onAutoCompleteSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.object)
  ])
};
