(function() {
  $('.top-menu-rows-button').on('click', function(e) {
    e.preventDefault();
    /* When rows (hamburger) button is pressed */
    if (document.getElementById('display-dropdown-block').style.display === 'none') {
      document.getElementById('display-dropdown-block').style.display = 'block';
      document.getElementById('display-dropdown-overlay').style.display = 'block';
    } else {
      document.getElementById('display-dropdown-block').style.display = 'none';
      document.getElementById('display-dropdown-overlay').style.display = 'none';
    }

    // prettier-ignore
    if (document.getElementById('display-search-block').style.display === 'block'
      && document.getElementById('display-dropdown-block').style.display === 'block') {
      document.getElementById('display-dropdown-block').style.top = '80px';
    } else {
      document.getElementById('display-dropdown-block').style.top = '40px';
    }

    $('i', this).toggleClass('ss-rows ss-delete');
  });

  $('.top-menu-search-button').on('click', function(e) {
    e.preventDefault();
    /* When search button is pressed */
    if (document.getElementById('display-search-block').style.display === 'none') {
      document.getElementById('display-search-block').style.display = 'block';
      //$('.display-search-block').slideDown("fast");
      document.getElementById('icaf-global-search-input').focus();
    } else {
      //$('.display-search-block').slideUp("fast");
      document.getElementById('display-search-block').style.display = 'none';
    }

    // prettier-ignore
    if (document.getElementById('display-search-block').style.display === 'block'
      && document.getElementById('display-dropdown-block').style.display === 'block') {
      document.getElementById('display-dropdown-block').style.top = '80px';
    } else {
      document.getElementById('display-dropdown-block').style.top = '40px';
    }
  });
})();
