import styled from 'styled-components/macro';
/* eslint-disable */
import { WHITE } from '../../../constants';
import PropTypes from 'prop-types';
export const MapBlockContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const InfoArea = styled.div`
  // max-width: 1180px;
  margin: 0 auto;
  width: 100%;
  display: inherit;
  margin-bottom: 20px;

  .info-area-inner {
    background-color: transparent;
    // padding: 1.25rem 1.25rem 0.625rem 1.25rem;
    max-height: 26.25rem !important;
    //
    max-width: 530px;
    word-break: break-word;

    & h1 {
      color: #3a3a3a;
    }
  }
`;

export const ItemContainer = styled.div`
  height: 100%;
  width: ${({ w }) => (w ? `${w}%` : '100%')};
  min-height: 450px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 1200px) {
    width: 30%;
    &:first-of-type {
      width: 68%;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    min-height: 0;

    margin-bottom: 0.5rem;
    &:first-of-type {
      width: 100%;
    }
  }

  &:before {
    overflow: hidden;
    content: 'laddar';
    transition: opacity 0.3s ease;
    display: ${({ showOverlay }) => (showOverlay ? 'flex' : 'flex')};
    height: ${({ showOverlay }) => (showOverlay ? '100%' : 0)};
    opacity: ${({ showOverlay }) => (showOverlay ? 1 : 0)};
    width: ${({ showOverlay }) => (showOverlay ? '100%' : 0)};
    z-index: 100;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    color: ${WHITE};
  }

  .item-container-flex {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: none !important;
  }
`;

ItemContainer.propTypes = {
  w: PropTypes.number
};
