$(document).ready(function() {
  window.onload = function FilterTabExp() {
    let buttons = document.querySelectorAll('.expand-filter-tabs');
    buttons.forEach((button, idx) => {
      button.addEventListener('click', () => {
        let contents = document.querySelectorAll('.icaf-tabs__expand');
        let expanded = button.getAttribute('data-expanded');

        if (expanded === 'true') {
          button.setAttribute('data-expanded', 'false');
        } else {
          button.setAttribute('data-expanded', 'true');
        }
        contents.forEach((content, contentIdx) => {
          if (contentIdx === idx) {
            let hidden = content.getAttribute('data-hidden');

            if (hidden === 'true') {
              content.setAttribute('data-hidden', 'false');
            } else {
              content.setAttribute('data-hidden', 'true');
            }
          }
        });
      });
    });
  };
});
