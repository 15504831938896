import InstagramFeed from './InstagramFeed.min.js';
let captionList = [];
let slideIndex = 1;
showSlides(slideIndex);

(function() {
  if (document.getElementById('instagramblock-wrapper') !== null) {
    const imgcount = parseInt(document.getElementById('image_count').innerText);
    const username = document.getElementById('username').innerText;

    new InstagramFeed({
      username: username,
      get_data: true,
      callback: function(data) {
        try {
          if (data !== null) {
            for (let i = 1; i < imgcount + 1; i++) {
              document.getElementById('instagram-content__img__' + i).style.backgroundImage =
                'url(' + data.edge_owner_to_timeline_media.edges[i - 1].node.display_url + ')';
              document.getElementById('instagram-content__img__' + i).style.display = 'flex';
              document.getElementById('instagram-content__img__' + i).style.flexWrap = 'wrap';
              document.getElementById('instagram-content__img__' + i).style.position = 'relative';
              document.getElementById('instagram-content__img__' + i).style.padding = '0';
              document.getElementById('instagram-content__img__' + i).style.width =
                'calc(25% - 15px)';
              document.getElementById('instagram-content__img__' + i).style.height = '283px';
              document.getElementById('instagram-content__img__' + i).style.minWidth = '270px';
              document.getElementById('instagram-content__img__' + i).style.minHeight = '283px';
              document.getElementById('instagram-content__img__' + i).style.backgroundSize =
                'cover';
              document.getElementById('instagram-content__img__' + i).style.backgroundRepeat =
                'no-repeat';
              document.getElementById('instagram-content__img__' + i).style.textAlign = 'center';
              document.getElementById('instagram-content__img__' + i).style.marginLeft = '15px';
              document.getElementById('instagram-content__img__likes__' + i).style.backgroundColor =
                'white';
              document.getElementById('instagram-content__img__likes__' + i).style.color = 'black';
              document.getElementById('instagram-content__img__likes__' + i).style.position =
                'absolute';
              document.getElementById('instagram-content__img__likes__' + i).style.fontFamily =
                'ica-text-bold, sans-serif';
              document.getElementById('instagram-content__img__likes__' + i).style.fontSize =
                '14px';
              document.getElementById('instagram-content__img__likes__' + i).style.bottom = '0px';
              document.getElementById('instagram-content__img__likes__' + i).style.paddingLeft =
                '22px';
              document.getElementById('instagram-content__img__likes__' + i).style.display = 'flex';
              document.getElementById('instagram-content__img__likes__' + i).style.alignItems =
                'center';
              document.getElementById('instagram-content__img__likes__' + i).style.height = '30px';
              document.getElementById('instagram-content__img__likes__' + i).style.width = '100px';
              document.getElementById('instagram-content__img__likes__' + i).innerHTML =
                data.edge_owner_to_timeline_media.edges[i - 1].node.edge_liked_by.count;

              if (window.innerWidth >= 768) {
                document.getElementById('instagram-modal__img__' + i).src =
                  data.edge_owner_to_timeline_media.edges[i - 1].node.display_url;
                // prettier-ignore
                captionList.push(
                  data.edge_owner_to_timeline_media.edges[i - 1].node.edge_media_to_caption.edges[0].node.text
                );
                document.getElementById('instagram-content__img__' + i).onmouseover = function() {
                  document.getElementById('instagram-content__img__' + i).style.cursor = 'pointer';
                };
                document.getElementById('instagram-content__img__' + i).onclick = function() {
                  openModal();
                  currentSlide(i);
                };
                document.getElementById('close').onclick = function() {
                  closeModal();
                };
                document.getElementById('prev').onclick = function() {
                  plusSlides(-1);
                };
                document.getElementById('next').onclick = function() {
                  plusSlides(1);
                };
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  }
})();

function openModal() {
  if (document.getElementById('instagramblock-wrapper') !== null) {
    document.getElementById('myModal').style.display = 'block';
  }
}

function closeModal() {
  if (document.getElementById('instagramblock-wrapper') !== null) {
    document.getElementById('myModal').style.display = 'none';
  }
}

// Next/previous controls
function plusSlides(n) {
  if (document.getElementById('instagramblock-wrapper') !== null) {
    showSlides((slideIndex += n));
  }
}

// Thumbnail image controls
function currentSlide(n) {
  if (document.getElementById('instagramblock-wrapper') !== null) {
    showSlides((slideIndex = n));
  }
}

function showSlides(n) {
  if (document.getElementById('instagramblock-wrapper') !== null) {
    let i;
    let slides = document.getElementsByClassName('mySlides');
    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }

    slides[slideIndex - 1].style.display = 'block';
    document.getElementById('caption').innerHTML = captionList[slideIndex - 1];
  }
}
