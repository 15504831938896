document.addEventListener('DOMContentLoaded', function() {
  $(document).on('click', '.project-sort-status-0', function() {
    $('.icaf-tabs__tab-wrapper a').removeClass('active');
    jQuery(this).addClass('active');
    $('.project-list-section-wrapper').show();
  });

  $(document).on('click', '.project-sort-status-1', function() {
    $('.icaf-tabs__tab-wrapper a').removeClass('active');
    jQuery(this).addClass('active');
    $('.project-list-section-wrapper').hide();
    $('.upcoming-projects').show();
  });

  $(document).on('click', '.project-sort-status-2', function() {
    $('.icaf-tabs__tab-wrapper a').removeClass('active');
    jQuery(this).addClass('active');
    $('.project-list-section-wrapper').hide();
    $('.in-progress-projects').show();
  });
  $(document).on('click', '.project-sort-status-3', function() {
    $('.icaf-tabs__tab-wrapper a').removeClass('active');
    jQuery(this).addClass('active');
    $('.project-list-section-wrapper').hide();
    $('.previous-projects').show();
  });

  $(document).on('click', '.map-filter', function() {
    let $that = jQuery(this);
    let ajaxUrl = $that.attr('data-ajax-url');
    $('.icaf-tabs__tab-wrapper a.map-filter').removeClass('active');
    $that.addClass('active');
    jQuery.get(ajaxUrl).then(function(response) {
      let tmpResponse = response;
      response = jQuery("<div class='block tabmapblock large-12 column'></div>");
      response.html(tmpResponse);
      jQuery('.map-filter-class').html(response);

      let str = $('.block.tabmapblock.large-12.column')
        .find('div')
        .attr('data-interchange');
      let imageUrl = str.substring(str.indexOf('[') + 1, str.indexOf('small'));

      let width = '';
      if ($(window).width() <= 480) {
        width = 'small';
      } else if ($(window).width() > 480 && $(window).width() <= 768) {
        width = 'medium';
      } else {
        width = 'large';
      }
      imageUrl = imageUrl + width;

      $('.block.tabmapblock.large-12.column')
        .children(':first')
        .css('background-image', 'url(' + imageUrl + ')');

      if (document.getElementById('map') == null) {
        return;
      }

      const mapCheck = document.getElementById('map');

      if (!mapCheck) return;

      async function initMap(mapID) {
        const mapCheck = document.getElementById('map');
        if (!mapCheck) return;
        const locArray = JSON.parse(mapCheck.getAttribute('data-map-locations') || '[]');
        let infoWindowContent = mapCheck.getAttribute('info-window-content');

        if (!locArray.length) return;

        const latitude = parseFloat(locArray[0].lat.replace(',', '.'));
        const longitude = parseFloat(locArray[0].lng.replace(',', '.'));

        const myLatLng = { lat: latitude, lng: longitude };
        const { Map } = await google.maps.importLibrary('maps');
        const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

        // Map options (center on the first location in array)
        const mapOptions = {
          zoom: 8,
          center: new google.maps.LatLng(myLatLng),
          mapId: mapID // Add your mapID here
        };

        // Create the map with mapID
        const map = new Map(document.getElementById('map'), mapOptions);

        const marker = new AdvancedMarkerElement({
          position: new google.maps.LatLng(myLatLng),
          map: map,
          title: 'Klicka för info' // Add title to each marker
        });

        const infowindow = new google.maps.InfoWindow({
          content: `<h4>Kontaktuppgifter</h4><p>${infoWindowContent}</p>`
        });

        // Add click listener to show info window
        marker.addListener('click', function() {
          infowindow.open(map, marker);
        });
      }

      // Specify your mapID (custom style ID from Google Cloud Console)
      const mapID = '58e8ab770daa76e6';

      // Initialize the map with locations and mapID
      initMap(mapID);
    });
  });

  $(document).on('click', '.contact-filter', function() {
    let $that = jQuery(this);
    let ajaxUrl = $that.attr('data-ajax-url');
    $('.icaf-tabs__tab-wrapper a.contact-filter').removeClass('active');
    $that.addClass('active');
    jQuery.get(ajaxUrl).then(function(response) {
      let tmpResponse = response;
      response = jQuery("<div class='xmldata'></div>");
      response.html(tmpResponse);

            jQuery('#contactFilter').html(response);
        });
    });

    $(document).on('click', '.text-block-filter', function () {
        let $that = jQuery(this);
        let ajaxUrl = $that.attr('data-ajax-url');
        $('.icaf-tabs__tab-wrapper a.text-block-filter').removeClass('active'); 
        $('.icaf-tabs__tab-wrapper-small-buttons a.text-block-filter').removeClass('active');
        $that.addClass('active');
        jQuery.get(ajaxUrl).then(function (response) {
            let tmpResponse = response;
            response = jQuery("<div class='xmldata'></div>");
            response.html(tmpResponse);

            jQuery('#textBlockFilter').html(response);
        });
    });
});