export const setupMutationObserver = (targetElement, onMutate = () => {}, attrs = {}) => {
  if ('MutationObserver' in window && targetElement) {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        onMutate(mutation);
      });
    });

    const observerConfig = {
      attributes: true,
      childList: true,
      characterData: true,
      ...attrs
    };
    observer.observe(targetElement, observerConfig);
  }
};
