import React from 'react';
import { fastighetsFilterBlockPropTypes } from './propTypes';
import { FilterContainer } from './components';
const FastighetsFilterBlock = ({ header, image, filterCategory, ...rest }) => {
  const [{ value, type }] = filterCategory;
  return (
    <>
      <FilterContainer value={value} type={type} header={header} image={image} {...rest} />
    </>
  );
};

FastighetsFilterBlock.defaultProps = {
  onSelect: () => {},
  onDeSelect: () => {}
};
FastighetsFilterBlock.propTypes = fastighetsFilterBlockPropTypes.isRequired;

export { FastighetsFilterBlock };
