class GeoLocator {
  constructor() {
    if (!('geolocation' in navigator)) {
      console.warn('geolocation in navigator:', 'geolocation' in navigator);
      return;
    }
  }

  getPosition = () => {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    );
  };

  getCoordinates = async () => {
    try {
      const { coords } = await this.getPosition();
      return coords;
    } catch (ex) {
      //Any case where the user coordinates cannot be accessed. For example If the user has declined.
      return { longitude: undefined, latitude: undefined };
    }
  };
}

export { GeoLocator };
