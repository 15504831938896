import styled from 'styled-components/macro';
import { BaseButton } from './BaseButton';
import { TEXT_GRAY, ICA_RED } from '../../constants';

export const TransparentButton = styled(BaseButton)`
  font-size: 1.125rem !important;
  line-height: 1.25 !important;
  font-weight: 700 !important;
  font-family: 'ica-text-black', sans-serif !important;
  color: #e13205;
  display: ${({ isDisabled }) => (isDisabled ? 'none !important' : 'flex')};
  // pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  transition: color 0.2s ease;

  &::before {
    // content: '\\d7';
    font-size: 2.2rem;
    color: '#cccccc';
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    transition: transform 0.2s ease;
    transform: translateX(${({ hidden }) => (hidden ? '1.5rem' : 0)});
    margin-top: -3px;
    display: flex;
    align-items: center;
  }

  &:focus,
  &:active,
  &:hover {
    // color: ${({ isDisabled }) => (isDisabled ? TEXT_GRAY : ICA_RED)};
    color: #9B0600;
  }
`;
