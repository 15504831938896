window.app.displayTemplates = (function() {
  let searchPage = function(jsondata) {
    let markup = '';

    jsondata.forEach((item, index) => {
      markup += `
        <a id="result-link-id-${index + 1}" href="${item.Url}">
          <h2>${item.Title}</h2>
          <p>${item.Text}</p>
        </a>
      `;
    });

    return markup;
  };

  let searchDropDown = function(jsondata) {
    let markup = '';

    for (let i = 0; i < jsondata.length; i++) {
      markup += "<li><a href='" + jsondata[i].Url + "'>" + jsondata[i].Title + '</a></li>';
    }

    return markup;
  };

  return {
    searchPage: searchPage,
    searchDropDown: searchDropDown
  };
})();
