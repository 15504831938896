import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import React from 'react';
import { ICA_RED, WHITE } from '../../../constants/colors';
export const FilterLabel = styled.label.attrs(() => ({}))`
  position: relative;
  margin: 0 !important;
  z-index: 1;
  display: grid !important;
  grid-template-columns: 2rem auto;
  column-gap: 1rem;
  align-items: center;
  justify-content: left;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 0.5rem !important;
  background-color: #f9f5f5;
  border-radius: 0.25rem;
  outline: 0 solid #7fb570;
  outline-offset: 0;
  color: #e13205 !important;
  font-size: 1.125rem !important;
  line-height: 1.25  !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  text-transform: none !important;
  font-family: 'ica-text-black', sans-serif !important;
  transition: background-color 0.1s ease;
  width: 100% !important;

  @media (min-width: 48rem) {
    padding: 1.21875rem 1rem !important;
}

  &:hover,
  &:focus-visible {
    background-color: #fcece7;
    color: #e13205;
    cursor: pointer;
  }

  img {
    z-index: 0;
    // height: ${({ largeimage }) => (largeimage ? '2.5rem' : '1.7rem')};
    /* margin-top: ${({ largeimage }) => (largeimage ? 0 : 'auto')}; */
    
  }

& span {

    // @media only screen and (min-width: 900px) {
    //   display:${({ hiddenOnDesktop }) => (hiddenOnDesktop ? 'none' : 'inline-block')}
    // }
  }
`;

export const FilterCheckbox = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  cursor: pointer;
  display: none;
  &:checked + ${FilterLabel} {
    background: ${({ selectedbg }) => (selectedbg ? selectedbg : ICA_RED)};
    p {
      color: ${WHITE};
    }
  }
`;

export const FilterWrapperItem = styled.div`
  display: flex;
  width: 100%;
  // height: 4rem;
  position: relative;
  flex-wrap: wrap;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 33.3333%;
    padding: 0.5rem;
  }
`;

export const FilterContainer = ({
  image,
  value,
  header,
  type,
  onChange,
  isSelected,
  selectedImage
  // selectedBackgroundColor = ICA_RED,
  // noTextDesktop
}) => {
  const imageWhenSelected = selectedImage || image;
  return (
    <FilterWrapperItem>
      <FilterCheckbox
        selectedbg="#9B0600"
        checked={isSelected}
        onChange={() => onChange({ value: value, type: type })}
        id={value}
        value={value}
        test={image}
      />
      <FilterLabel
        // hiddenOnDesktop={noTextDesktop}
        // largeimage={header ? false : true}
        htmlFor={value}>
        {image && (
          <img
            src={isSelected ? imageWhenSelected : image}
            alt={`ikon för fastigheten ${header}`}
          />
        )}
        <span style={{ color: isSelected ? '#ffffff' : 'inherit' }}>{header}</span>
      </FilterLabel>
    </FilterWrapperItem>
  );
};

FilterContainer.propTypes = {
  onChange: () => {},
  isSelected: false,
  header: undefined,
  type: 'unknown',
  selectedImage: undefined,
  noTextDesktop: false
};

FilterContainer.propTypes = {
  image: PropTypes.string.isRequired,
  selectedImage: PropTypes.string,
  value: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  type: PropTypes.string,
  noTextDesktop: PropTypes.bool,
  selectedBackgroundColor: PropTypes.string
};
