export const ICA_RED = '#EB1F07';
export const TRANSPARANT_ICA_RED = '#EB1F0760';
export const ERROR = '#FBD2CD';
export const ICA_PINK = '#fbd2cc';
export const ICA_BLUE = '#007095';
export const ICA_PURPLE = '#A02971';
export const WHITE = '#FFF';
export const TEXT_GRAY = '#666666';
export const LIGHT_GRAY = '#dddddd';
export const ICA_BLACK = '#000000';
