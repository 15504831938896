(function() {
  jQuery('[data-seperate-preamble-alignment]').each(function(index, element) {
    setTimeout(function() {
      let preambleHeight = jQuery(element).height();

      let absoluteBottomCoord = preambleHeight / 2;
      let negAbsoluteBottomCoord = '-' + absoluteBottomCoord + 'px';

      jQuery(element).css('bottom', negAbsoluteBottomCoord);
      jQuery(element)
        .parents('.block')
        .first()
        .css('margin-bottom', absoluteBottomCoord);
    }, 100);
  });
})();

(function() {
  jQuery('[data-seperate-preamble-alignment-hero]').each(function(index, element) {
    setTimeout(function() {
      let preambleHeight = jQuery(element).outerHeight();
      let absoluteBottomCoord = preambleHeight / 2;
      let negAbsoluteBottomCoord = '-' + absoluteBottomCoord + 'px';

      jQuery(element).css('bottom', negAbsoluteBottomCoord);
      jQuery(element)
        .parents('.block')
        .first()
        .css('margin-bottom', absoluteBottomCoord + 20);
    }, 100);
  });
})();
