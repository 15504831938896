import ReactDOM from 'react-dom';
import React from 'react';

export const getDomElementsForComponent = (type = '') => {
  const elements = document.querySelectorAll(`[data-react-component-${type}]`);
  if (!elements || !elements.length) return null;
  return elements;
};

export const getProps = (element = undefined) => {
  if (!element) return null;

  const props = element.dataset['props'];
  if (!props) return null;

  const json = JSON.parse(props);
  if (!json) return null;
  return json;
};

export const mountComponents = (type = '', Component) => {
  if (!Component) {
    console.error('You must provide a React Component to mountComponent.');
  }
  const elements = getDomElementsForComponent(type);
  if (!elements) return null;
  let arrayOfElements = Array.from(elements);
  for (let i = 0; i < arrayOfElements.length; i += 1) {
    const props = getProps(arrayOfElements[i]);
    if (!props) return null;
    ReactDOM.render(<Component {...props} />, elements[i]);
    console.info(`Mounted component ${type}. Total components: ${i + 1}`);
  }
};
