(function() {
  // Large-up does not have a mobile menu and its search field, therefor we do not continue
  if (Foundation.utils.is_large_up()) {
    return;
  }

  let $searchContainer = jQuery('.icaf-header-medium-down-global-row-search-widget');
  let $searchField = $searchContainer.find('input[type=search]');
  let searchPageUrl = $searchField.attr('data-search-page-url');

  // Each slide in the medium-down menu has a searchfield, so we need to make sure that all are populated
  $searchField.on('keyup', function(e) {
    let $that = jQuery(this);

    if ($that.val().length > 0) {
      $searchField.each(function() {
        jQuery(this).val($that.val());
      });
    }

    if (e.keyCode == 13 && $searchField.is(':focus') && $searchField.eq(0).val().length) {
      window.location.href = searchPageUrl + $searchField.eq(0).val();
    }
  });
})();
