import { Logger } from '../utils';
import axios from 'axios';
class RequestService {
  async getRequest(url) {
    try {
      Logger.Log(`%c🔁 fetching ${url}`, 'color: yellow');
      const { data: result } = await axios.get(url, { mode: 'cors' });
      const { isOk, errorMessage, data } = result;

      if (!isOk) {
        Logger.Warn(`%c failed: ${url}`, 'color: red', `${errorMessage}`);
        return result;
      }

      Logger.Log(`%c✔ fetched: ${url}`, 'color: green', data);

      return result;
    } catch (ex) {
      Logger.Error(`%c failed: ${url}`, 'color: red', `${ex}`);

      throw new Error(ex);
    }
  }
}

const RequestServiceObj = new RequestService();

export { RequestServiceObj as RequestService };
