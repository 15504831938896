(function() {
  let $icaTabs = jQuery('.icaf-tabs');

  let getAndRenderTabContent = function(url, $that) {
    $.get(url).then(function(xml) {
      $that
        .parents($icaTabs)
        .next('.row')
        .find('>.block')
        .html(xml);
      jQuery(document).foundation('equalizer', 'reflow');
      jQuery(document).foundation('interchange', 'reflow');
      // initMap();
    });
  };

  if (Foundation.utils.is_small_only()) {
    $icaTabs.find('select').on('change', function(e) {
      e.preventDefault();
      getAndRenderTabContent(jQuery(this).val(), jQuery(this));
    });
  } else {
    $icaTabs.find('>ul>li').on('click', function(e) {
      e.preventDefault();
      getAndRenderTabContent(jQuery(this).attr('data-ajax-url'), jQuery(this));
      jQuery(this)
        .siblings('li')
        .removeClass();
      jQuery(this).addClass(jQuery(this).attr('data-ajax-class'));
    });
  }
})();
