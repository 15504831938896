(function() {
  // .block.groupblock is used for no spacing between blocks + equal size of blocks
  // .groupblock (like on 'Shared/Blocks/TabContact.cshtml') is used for no spacing between blocks
  //
  //

  if (!jQuery('.block.groupblock').length || !Foundation.utils.is_large_up()) {
    return;
  }

  jQuery('.block.groupblock').each(function(index, element) {
    // Equalize height of blocks in group block

    jQuery(element)
      .attr('data-equalizer', 'groupblock')
      .attr('data-options', 'equalize_on_stack: true')
      .attr('data-equalizer-mq', 'large-up');

    jQuery(element)
      .find('[data-put-group-watch-on-me]')
      .attr('data-equalizer-watch', 'groupblock');

    // Design fix if groupblock has small content blocks
    if (jQuery(element).find('.contentblock.large-3').length) {
      jQuery(element).addClass('has-several-content-block');
    }
  });

  jQuery(document).foundation('equalizer', 'reflow');
})();
