import styled from 'styled-components/macro';
import { BaseButton } from './BaseButton';
import closeRed from '../../img/icons/close.red.svg';
import closeBlack from '../../img/icons/close.svg';

const size = '1.8rem';
export const Close = styled(BaseButton)`
  width: ${size};
  height: ${size};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    cursor: pointer;
    position: absolute;
    content: '';
    height: calc(${size} * 0.7);
    width: calc(${size} * 0.7);
    background: url(${closeBlack});
    transition: background 0.2s ease;
  }
`;

const background = 'rgba(255, 255, 255, 0.6)';
export const SecondaryClose = styled(Close)`
  padding: 0.2rem;
  background: ${background};

  &:before {
    background-image: url(${closeRed});
    background-position: center center;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &:hover,
  &:focus {
    background: ${background};
  }
`;
