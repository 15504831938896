$(document).ready(function() {
  let buttons = document.querySelectorAll('.pc-item');

  buttons.forEach((button, idx) => {
    button.addEventListener('mouseenter', () => {
      let contents = document.querySelectorAll('.pc-step-info');
      let expanded = button.getAttribute('aria-expanded');

      if (expanded === 'true') {
        button.setAttribute('aria-expanded', 'false');
      } else {
        button.setAttribute('aria-expanded', 'true');
      }
      contents.forEach((content, contentIdx) => {
        if (contentIdx === idx) {
          let hidden = content.getAttribute('aria-hidden');

          if (hidden === 'true') {
            content.setAttribute('aria-hidden', 'false');
          } else {
            content.setAttribute('aria-hidden', 'true');
          }
        }
      });
    });
    button.addEventListener('mouseleave', () => {
      let contents = document.querySelectorAll('.pc-step-info');
      let expanded = button.getAttribute('aria-expanded');

      if (expanded === 'true') {
        button.setAttribute('aria-expanded', 'false');
      } else {
        button.setAttribute('aria-expanded', 'true');
      }

      contents.forEach((content, contentIdx) => {
        if (contentIdx === idx) {
          let hidden = content.getAttribute('aria-hidden');
          if (hidden === 'true') {
            content.setAttribute('aria-hidden', 'false');
          } else {
            content.setAttribute('aria-hidden', 'true');
          }
        }
      });
    });
  });
});
