$(document).ready(function() {
  $(document).on('change', '#newsyear', function() {
    const $that = jQuery(this);
    const ajaxUrl = $that.find(':selected').val();

    $.get(ajaxUrl)
      .then(function(response) {
        let shouldScroll = true;
        let loadMoreUrl = '';
        let hasMoreHits = false;
        let parsedResponse = jQuery("<div class='xmldata'></div>").html(response);
        $('.icaf-searchlist-news-hits').html(parsedResponse);

        $('.icaf-searchlist-news-hits').html(response);

        loadMoreUrl = parsedResponse.find('input[type=hidden]:first').val();
        hasMoreHits = !!loadMoreUrl;

        $('#load-more-news')
          .val(loadMoreUrl)
          .attr('data-icaf-load-more-ajax-url', loadMoreUrl);

        if (hasMoreHits) {
          $('#load-more-news').show();
          $('.icaf-searchlist-news-loadmore').removeClass('hide');
        } else {
          $('.icaf-searchlist-news-loadmore').addClass('hide');
          $('#load-more-news').hide();
        }

        $(document).foundation('equalizer', 'reflow');
        $(document).foundation('interchange', 'reflow');

        if (!shouldScroll) return;
      })
      .fail(function(error) {
        console.error(error);
      });
  });
});
