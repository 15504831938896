const getProfil = (profil = []) =>
  profil.reduce((acc, { text = '' }) => {
    const comma = acc.length ? '' : '';
    return `${acc} ${comma} ${text}`;
  }, '');

// const capitalize = s => {
//   if (typeof s !== 'string') return '';
//   return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
// };

export const profilToText = ({ profil = [], postArea }) =>
  `${getProfil(profil).trim()}, ${postArea}`;

export const profilToCommaSeparated = (profil = [], separator = ' ') =>
  profil.reduce((acc, { text = '' }) => {
    // const capitalized = capitalize(text);
    const comma = acc.length ? separator : '';
    return `${acc}${comma} ${text}`;
  }, '');
