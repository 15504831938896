document.addEventListener('DOMContentLoaded', function() {
  //  const mapCheck = document.getElementById('map');

  //  let mapElement;

  //  if (!mapCheck) return;

  //  const locArray = JSON.parse(mapCheck.getAttribute('data-map-locations') || '[]');
  //  let infoWindowContent = mapCheck.getAttribute('info-window-content');
  //  const displayInfoWindowOnLoad = infoWindowContent ? true : false;

  //  if (!locArray.length) return;

  //  const latitude = parseFloat(locArray[0].lat.replace(',', '.'));
  //  const longitude = parseFloat(locArray[0].lng.replace(',', '.'));

  //  const myLatLng = { lat: latitude, lng: longitude };

  //  async function initMap() {
  //      const { Map } = await google.maps.importLibrary("maps");
  //      //const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  //      mapElement = new Map(document.getElementById("map"), {
  //          center: myLatLng,
  //          zoom: 8,
  //          mapID: '58e8ab770daa76e6'
  //      });
  //  }

  //  initMap();

  //  const map = new google.maps.Map(mapCheck, {
  //      center: myLatLng,
  //      zoom: 9,
  //      mapID: '58e8ab770daa76e6'
  //  });

  //  const contentString = `
  //  <div class="mapblock-text-area-inner">
  //    <h2 class="text-center">${locArray[0].title}</h2>
  //    <div class="icaf-tiny-mce-container">${infoWindowContent}</div>
  //  </div>
  //`;

  //  const infowindow = new google.maps.InfoWindow({
  //      content: `<div id="iw_content">${contentString}</div>`
  //  });

  //  const marker = new google.maps.marker.AdvancedMarkerElement({
  //      map,
  //      position: new google.maps.LatLng(myLatLng),
  //      title: 'Klicka för info!',
  //  });
  //  marker.map = map;

  //  marker.addListener('click', () => {
  //      infowindow.open(map);
  //      map.setCenter(marker.position);
  //      adjustInfoWindowStyle();
  //  });

  //  function adjustInfoWindowStyle() {
  //      const infoWindowElement = document.querySelector('.gm-style-iw');
  //      let hej = document.getElementById('iw_content');
  //      if (!hej) {
  //          return;
  //      }
  //      const isMobile =
  //          window.getComputedStyle(document.getElementById('iw_content')).width === '200px';

  //      if (infoWindowElement) {
  //          const parentElement = infoWindowElement.parentElement;
  //          if (parentElement) {
  //              parentElement.style.left = isMobile ? '1.5625rem' : '6.5625rem';
  //              parentElement.style.top = isMobile ? '3.5625rem' : '6.5625rem';
  //          }

  //          infoWindowElement.previousElementSibling.innerHTML = ''; // Clear previous sibling content
  //          infoWindowElement.style.backgroundColor = 'white';
  //          infoWindowElement.style.padding = '15px';
  //          infoWindowElement.style.left = '20px';
  //      }
  //  }

  //  if (displayInfoWindowOnLoad) {
  //      google.maps.event.addListenerOnce(map, 'idle', () => {
  //          google.maps.event.trigger(marker, 'click');
  //      });
  //  }

  /*#############################################################################################*/

  async function initMap(mapID) {
    const mapCheck = document.getElementById('map');
    if (!mapCheck) return;
    const locArray = JSON.parse(mapCheck.getAttribute('data-map-locations') || '[]');
    let infoWindowContent = mapCheck.getAttribute('info-window-content');
    // const displayInfoWindowOnLoad = infoWindowContent ? true : false;

    if (!locArray.length) return;

    const latitude = parseFloat(locArray[0].lat.replace(',', '.'));
    const longitude = parseFloat(locArray[0].lng.replace(',', '.'));

    const myLatLng = { lat: latitude, lng: longitude };
    const { Map } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

    // Map options (center on the first location in array)
    const mapOptions = {
      zoom: 8,
      center: new google.maps.LatLng(myLatLng),
      mapId: mapID // Add your mapID here
    };

    // Create the map with mapID
    const map = new Map(document.getElementById('map'), mapOptions);

    const marker = new AdvancedMarkerElement({
      position: new google.maps.LatLng(myLatLng),
      map: map,
      title: 'Klicka för info' // Add title to each marker
    });

    const infowindow = new google.maps.InfoWindow({
      content: `<h4>Kontaktuppgifter</h4><p>${infoWindowContent}</p>`
    });

    // Add click listener to show info window
    marker.addListener('click', function() {
      infowindow.open(map, marker);
    });
  }

  // Specify your mapID (custom style ID from Google Cloud Console)
  const mapID = '58e8ab770daa76e6';

  // Initialize the map with locations and mapID
  initMap(mapID);
});
//});
