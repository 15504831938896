import styled from 'styled-components/macro';

export const BaseButton = styled.button`
  all: unset;
  background: none;
  border: none;
  outline: none;
  display: block;
  background: none;
  cursor: pointer;

  &:hover {
    background: none;
  }
  &:active,
  &:focus {
    border: none;
    outline: none;
    background: none;
  }

  &:before,
  &:after {
    cursor: pointer;
    background: none;
  }
`;
