import styled from 'styled-components/macro';
import { FilterWrapperItem, FilterLabel } from './FilterContainer';

export const FilterBlockContainer = styled.div`
  display: flex;
  // max-width: 460px;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: stretch;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & > span {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-family: 'ica-rubrik-bold', sans-serif;
    overflow-wrap: anywhere;
    color: #3a3a3a;
    text-align: left;
    margin-bottom: 0.75rem;
  }

  @media only screen and (max-width: 900px) {
    margin-bottom: 0.3rem;
  }
`;

// desktop version
export const FilterItemGrid = styled.div`
  display: flex;

  flex-direction: column;
  row-gap: 6px;

  & > div:not(div:last-child)::after {
    content: '';
    position: absolute;
    background-color: #f2e3d8;
    bottom: -4px;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: 2px;
    border-radius: 2px;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

// mobile version
export const FilterItemColumn = styled.div`
  display: none;

  flex-direction: column;
  row-gap: 6px;

  & > div:not(div:last-child)::after {
    content: '';
    position: absolute;
    background-color: #f2e3d8;
    bottom: -4px;
    left: 0.5rem;
    width: calc(100% - 1rem);
    height: 2px;
    border-radius: 2px;
  }

  @media only screen and (max-width: 900px) {
    display: flex;
  }

  // This is for buttons in mobile
  ${FilterWrapperItem} {
    img {
      //display: none;
      width: 0px;
    }

    span {
      position: relative;
      left: -3rem;
    }
  }

  ${FilterLabel} {
    padding: 1rem;

    p {
      font-size: 1rem;
      text-align: left;
    }
  }
`;
