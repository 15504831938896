(function() {
  let cookieButton = jQuery('.icaf-cookie-bar .button');
  let cookieBanner = jQuery('.icaf-cookie-bar');

  let accepted = localStorage.getItem('cookieAccepted');
  if (!accepted) {
    cookieBanner.show();
  }
  cookieButton.on('click', function() {
    cookieBanner.hide();
    localStorage.setItem('cookieAccepted', 'yes');
  });
})();
