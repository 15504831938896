(function() {
  jQuery('.icaf-view').on('click', '[data-icaf-toggle-display-click]', function(e) {
    e.preventDefault();

    let hideClassToToggle = jQuery(this).attr('data-icaf-toggle-display-click'); // hide, hide-for-small, etc

    jQuery(this)
      .find('.ss-icon')
      .toggleClass('ss-navigatedown ss-navigateup');
    jQuery(this)
      .find('[data-icaf-toggle-display-click-text]')
      .toggleClass('hide');
    jQuery(this)
      .siblings('[data-icaf-toggle-display]')
      .toggleClass(hideClassToToggle);
  });
})();
