(function() {
  // medium-down does not have search auto complete functionality, therefor we do not continue
  if (!Foundation.utils.is_large_up()) {
    return;
  }

  let liIndex = -1;
  let $searchContainer = jQuery('.icaf-header-large-up-global-row-search-widget');
  let $autoCompleteContainer = $searchContainer.find(
    '.icaf-header-large-up-global-row-search-widget-autocomplete'
  );
  let $loadMoreButtonContainer = $autoCompleteContainer.find('>div');
  let $loadMoreButton = $loadMoreButtonContainer.find('button');
  let $ul = $autoCompleteContainer.find('ul');
  let $searchField = $searchContainer.find('input[type=search]');
  let searchApiUrl = $searchField.attr('data-search-api-url');
  let searchPageUrl = $loadMoreButton.attr('data-search-page-url');

  let resetAutoComplete = function() {
    $ul.empty();
    $autoCompleteContainer.addClass('hide');
    $loadMoreButtonContainer.addClass('hide');
  };

  let setActiveClass = function() {
    $ul.find('li').removeClass('active');
    $ul.find('li:eq(' + liIndex + ')').addClass('active');
  };

  let getAndRenderAjaxData = function(url) {
    $.get(url).then(function(response) {
      if (response.resultHits.Hits.length) {
        liIndex = -1;

        let markup = window.app.displayTemplates.searchDropDown(response.resultHits.Hits);

        $ul.html(markup);

        if (response.hasMoreHits) {
          $loadMoreButtonContainer.removeClass('hide');
        } else {
          $loadMoreButtonContainer.addClass('hide');
        }

        $autoCompleteContainer.removeClass('hide');
      } else {
        resetAutoComplete();
      }
    });
  };

  $searchField.on(
    'keyup',
    Foundation.utils.throttle(function(e) {
      // Shit, left arrow, right arrow, alt, alt ggr
      if (
        e.keyCode == 16 ||
        e.keyCode == 37 ||
        e.keyCode == 39 ||
        e.keyCode == 17 ||
        e.keyCode == 18
      ) {
        return;
      }

      // Enter keypress with active field with value - active li, then navigate to it - else navigate to search page with query
      if (e.keyCode == 13 && $searchField.is(':focus') && $searchField.val().length) {
        if ($ul.find('li').hasClass('active')) {
          window.location.href = $ul
            .find('li.active')
            .find('>a')
            .attr('href');
        } else {
          window.location.href = searchPageUrl + $searchField.val();
        }
      }
      // Down keypress
      else if (e.keyCode == 40) {
        if (liIndex < $ul.find('li').length - 1) {
          liIndex++;
        }
        setActiveClass();
      }
      // Up keypress
      else if (e.keyCode == 38) {
        if (liIndex > 0) {
          liIndex--;
        }
        setActiveClass();
      }
      // User is typing other characters
      else {
        // Make Ajax call and render if field has value
        if ($searchField.val().length) {
          getAndRenderAjaxData(searchApiUrl + $searchField.val());
        }
        // User has deleted the field value with backspace
        else {
          resetAutoComplete();
        }
      }
    }, 250)
  );

  // Navigate to searchpage, with query, when user clicks the button
  $loadMoreButton.on('click', function() {
    window.location.href = searchPageUrl + $searchField.val();
  });

  // Hide autocomplete box, when user clicks outside it
  jQuery(document).click(function(e) {
    if (!$autoCompleteContainer.is(e.target) && $autoCompleteContainer.has(e.target).length === 0) {
      $autoCompleteContainer.addClass('hide');
    }
  });
})();
