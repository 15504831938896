(function() {
  jQuery(document).foundation({
    orbit: {
      slide_number: false,
      timer: false,
      navigation_arrows: true
    }
  });

  jQuery(window).on('orientationchange', function() {
    location.reload(); // Reload from cache (to reset responsive features set with javascript)
  });

  // Put Global Variables on app property
  window.app = {};
})();
