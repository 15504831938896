import styled from 'styled-components/macro';

export const MapContainer = styled.div`
  height: ${({ height }) => height || '50vh'};
  width: 100%;
  overflow: hidden;
  max-width: 1180px;
  margin: 0 auto;

  .gm-style-iw-a {
    display: none;
  }
`;
